@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css);
html {
  height: 100%!important
}

#root {
  height: 100%
}
body {
  height: 100%
}
a {
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}

.selectedPageLink {
  color: yellow;
  /* font-weight: bolder; */
}

.action-icon {
  margin-left: 2.5px;
  margin-right: 2.5px;
  color: #0b5ed7;
}

.nowrap {
  white-space: nowrap;
}

#bottom-logo-wrapper {
  width: 252px;
}

.Unauthenticated.container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}

table tbody tr td.buttoncell {
  white-space: nowrap;
}

div.buttoncell {
  white-space: nowrap;
}

.distribWholeSmallDropdownFont {
  font-size: small;
}

div.ltcPacketMessageStyle {
  background-color: #f8f8ff;
}

